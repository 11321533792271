import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {CloseBrokerRequestDTO, GetBrokerRequestDTO, GetBrokerResponseDTO, PetBreedDTO} from "./dto";
import {first, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  BASE_URL = environment.apiBaseUrl;

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  getBreed(specie: 'cat' | 'dog'): Observable<PetBreedDTO[]> {
    const lang = this.translateService.currentLang;
    if (specie === 'cat') {
      return this.httpClient.get<PetBreedDTO[]>(`${this.BASE_URL}/get-cat-breeds?lang=${lang}`).pipe(first());
    } else {
      return this.httpClient.get<PetBreedDTO[]>(`${this.BASE_URL}/get-dog-breeds?lang=${lang}`).pipe(first());
    }
  }

  getBrokerInfo(brokerCode: GetBrokerRequestDTO): Observable<GetBrokerResponseDTO> {
    return this.httpClient.post<GetBrokerResponseDTO>(`${this.BASE_URL}/get-broker`, brokerCode).pipe(first());
  }

  closeBreeder(data: CloseBrokerRequestDTO) {
    return this.httpClient.post(`${this.BASE_URL}/close-breeder-v2`, data).pipe(first());
  }
}
