import {PetBreedDTO} from "../../../services/dto";

export enum Pedigree {
  PEDIGREE = 'pedigree',
  CROSS = 'cross',
  MIXED = 'mixed',
}

export class VetPetInfo {
  brokerCode: string;
  species: 'cat' | 'dog';
  dogBreed?: string;
  catBreed?: string;
  breed: string;
  petBirthDate: string;
  neutered: boolean;
  petName: string;
  petGender: string;
  startDate: string;
  firstName: string;
  lastName: string;
  email: string;
  parentGender: string;
  parentBirthdate: string;
  street: string;
  number?: string;
  zip?: string;
  city: string;
  phoneNumber?: string;
  isBreeder: boolean;
  tallDog: boolean;
  housecat: boolean;

  coverageLevel: string;
  preexistingConditions: boolean;
  globalCoverage: boolean;
  liability: boolean;
  excess: number;
  copayment: number;

  paymentFrequency: string;
  paymentMethod: string;
  termsAndConditions: boolean;


  constructor(VAnumber: string, species: 'cat' | 'dog', breed: string, pedigree: string, petBirthDate: string, neutered: boolean, petName: string, petGender: string, startDate: string, firstName: string, lastName: string, email: string, parentGender: string, parentBirthdate: string, street: string, number: string, zip: string, city: string, phoneNumber: string, tallDog: boolean, housecat: boolean) {
    this.brokerCode = VAnumber;
    this.species = species;

    if (this.species === 'cat') {
      if (pedigree === 'mixed') {
        this.catBreed = 'mischlingskatze'
      } else {
        this.catBreed = breed;
      }
    } else {
      if (pedigree === 'mixed') {
        if (tallDog === true) {
          this.dogBreed = 'mischling_gross'
        } else {
          this.dogBreed = 'mischling_klein'
        }
      } else {
        this.dogBreed = breed;
      }
    }
    this.breed = pedigree;
    this.petBirthDate = petBirthDate;
    this.neutered = neutered;
    this.petName = petName;
    this.petGender = petGender;
    this.startDate = startDate;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.parentGender = parentGender;
    this.parentBirthdate = parentBirthdate;
    this.street = street;
    this.number = number;
    this.zip = zip;
    this.city = city;
    this.phoneNumber = phoneNumber;
    this.isBreeder = false;

    this.coverageLevel = 's';
    this.preexistingConditions = false;
    this.globalCoverage = true;
    this.liability = false;
    this.excess = 0;
    this.copayment = 20;

    this.paymentFrequency = 'm';
    this.paymentMethod = 'invoice';
    this.termsAndConditions = true;
    this.tallDog = tallDog;
    this.housecat = housecat;
  }
}
