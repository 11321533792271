import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'calingo-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  buttons = [
    {
      title: 'Breeder Upload',
      link: '/breeder-upload',
      active: true
    },
    {
      title: 'Vet Clinic Upload',
      link: '/vet-clinic-upload',
      active: false
    }
  ];

  currentLang = 'de';

  constructor(private router: Router, private translate: TranslateService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let button = this.buttons.find(button => button.link === event.url);
        if (button) {
          this.buttons.forEach(b => b.active = false);
          button.active = true;
        }
      }
    });

    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }
}
