import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import parsePhoneNumber from 'libphonenumber-js'

import { ApiHttpService } from "../../services/api-http.service";
import { PetBreedDTO } from "../../services/dto";
import { ActivatedRoute } from "@angular/router";
import { VetPetInfo } from "./dto/vetPetInfo";
import * as zipPlaceMap from 'src/assets/zipcode-place.json';
import { ReplaySubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'calingo-admin-vet-clinic-upload',
  templateUrl: './vet-clinic-upload.component.html',
  styleUrls: ['./vet-clinic-upload.component.scss']
})
export class VetClinicUploadComponent {
  form = new FormGroup({
    VAnumber: new FormControl(''/*, Validators.required*/),
    species: new FormControl('dog'),
    breed: new FormControl('', this.breedValidator()),
    tallDog: new FormControl(true, this.tallDogValidator()),
    pedigree: new FormControl('pedigree', Validators.required),
    housecat: new FormControl(true, this.houseCatValidator()),
    petBirthDate: new FormControl('', [Validators.required, this.maxDateValidator(this.maxPetBirthdate()), this.minDateValidator(this.minPetBirthdate())]),
    neutered: new FormControl(false, Validators.required),
    petName: new FormControl('', Validators.required),
    petGender: new FormControl('', Validators.required),
    startDate: new FormControl('', [Validators.required, this.minDateValidator(this.minStartDate())]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    parentGender: new FormControl('', Validators.required),
    parentBirthdate: new FormControl('', [Validators.required, this.maxDateValidator(this.maxParentBirthdate())]),
    street: new FormControl('', Validators.required),
    number: new FormControl(''),
    zip: new FormControl(''),
    city: new FormControl('', Validators.required),
    phoneNumber: new FormControl('')
  })

  breeds: PetBreedDTO[] = [];
  filteredBreeds: ReplaySubject<PetBreedDTO[]> = new ReplaySubject<PetBreedDTO[]>(1);
  searchText = '';

  breedFilterCtrl: FormControl = new FormControl();

  loading = false; // Tracks the loading state

  

  zipPlaceMapLocal = new Map<string, string>(Object.entries(zipPlaceMap));

  constructor(private apiHttpService: ApiHttpService, private route: ActivatedRoute, private snackBar: MatSnackBar, private translate: TranslateService) {
    this.route.queryParams.subscribe(params => {
      this.form.controls.VAnumber.setValue(params['bc']);
    });

    let species = this.form.get('species');
    species?.valueChanges.subscribe(() => {
      this.getBreed();
    });

    this.getBreed();

    this.form.controls.zip.valueChanges.subscribe(() => {
      let zip = this.form.controls.zip.value;

      if (!zip) {
        return;
      }

      let city = this.zipPlaceMapLocal.get(zip);

      if (city) {
        this.form.controls.city.setValue(city);
      }
    });
  }

  ngOnInit() {
    this.filteredBreeds.next(this.breeds);
    this.breedFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterBreeds();
      });
  }

  filterBreeds() {
    console.log(this.searchText)
    let searchText = this.breedFilterCtrl.value
    this.filteredBreeds.next(
      this.breeds.filter(breed =>
        breed.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }

  onOpen() {
    this.searchText = '';
    this.filterBreeds();
  }

  getBreed() {
    this.form.controls.breed.setValue('');
    let species = this.form.get('species')?.value;

    if (!species || (species !== 'dog' && species !== 'cat')) {
      species = 'dog';
    }

    this.apiHttpService.getBreed(species as 'cat' | 'dog').subscribe((response) => {
      this.breeds = response;
      this.filteredBreeds.next(this.breeds)
    });
  }

  showHouseCatQuestion() {
    if (!this.form) {
      return false;
    }

    let species = this.form.get('species')?.value;

    if (!species) {
      return false;
    }

    return species === 'cat';
  }

  showTallDogQuestion() {
    if (!this.form) {
      return false;
    }

    let species = this.form.get('species')?.value;
    let pedigree = this.form.get('pedigree')?.value;

    if (!species || !pedigree) {
      return false;
    }

    return species === 'dog' && pedigree === 'mixed';
  }

  showBreedSelector() {
    if (!this.form) {
      return false;
    }

    let species = this.form.get('species')?.value;
    let pedigree = this.form.get('pedigree')?.value;

    if (!species || !pedigree) {
      return false;
    }

    return pedigree === 'pedigree' || pedigree === 'cross';
  }

  tallDogValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.showTallDogQuestion()) {
        //return control.value == null || control.value == '' || control.value == undefined ? { required: true } : null;
        if (control.value === true || control.value === false) {
          return null;
        }
        return { required: true }
      } else {
        return null;
      }
    }
  }

  breedValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.showBreedSelector()) {
        return control.value == null || control.value == '' || control.value == undefined ? { required: true } : null;
      } else {
        return null;
      }
    }
  }

  houseCatValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.showHouseCatQuestion()) {
        // return control.value == null || control.value == '' || control.value == undefined ? { required: true } : null;
        if (control.value === true || control.value === false) {
          return null;
        }
        return { required: true }
      } else {
        return null;
      }
    }
  }

  minDateValidator(minDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      const minimumDate = new Date(minDate);
  
      return selectedDate < minimumDate ? { 'minDate': { value: control.value } } : null;
    };
  }

  maxDateValidator(maxDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      const maximumDate = new Date(maxDate);
  
      return selectedDate > maximumDate ? { 'maxDate': { value: control.value } } : null;
    };
  }

  onSubmit() {
    this.form.markAllAsTouched();

    //As this controls can be shown conditionally, we need to update them manually in case they are not shown (when not shown, they are not updated automatically)
    this.form.controls.breed.updateValueAndValidity();
    this.form.controls.tallDog.updateValueAndValidity();
    this.form.controls.housecat.updateValueAndValidity();

    if (this.form.invalid) {
      console.log('form invalid')
      return;
    }



    const vetInfoDTO = new VetPetInfo(this.form.get('VAnumber')!.value!!,
      this.form.get('species')!.value! as 'cat' | 'dog',
      this.form.get('breed')!.value!, this.form.get('pedigree')!.value!,
      this.form.get('petBirthDate')!.value!, this.form.get('neutered')!.value!,
      this.form.get('petName')!.value!, this.form.get('petGender')!.value!,
      this.form.get('startDate')!.value!, this.form.get('firstName')!.value!.trim(),
      this.form.get('lastName')!.value!.trim(), this.form.get('email')!.value!.trim(),
      this.form.get('parentGender')!.value!,
      this.form.get('parentBirthdate')!.value!,
      this.form.get('street')!.value!.trim(),
      this.form.get('number')!.value!.trim(),
      this.form.get('zip')!.value!.trim(),
      this.form.get('city')!.value!.trim(),
      parsePhoneNumber(this.form.get('phoneNumber')!.value!, 'CH')?.format('E.164')!,
      this.form.get('tallDog')!.value! || false,
      this.form.get('housecat')!.value! || false,
    )

    console.log(vetInfoDTO);

    this.loading = true; // Start the spinner
    this.apiHttpService.closeBreeder(vetInfoDTO).subscribe({
      next: (response: any) => {
        // nothing to do
      },
      error: (error: any) => {
        console.log(error)
        this.loading = false; // Stop the spinner on error
        this.snackBar.open(this.translate.instant('messages.error'), this.translate.instant('messages.close'), {
          duration: 0,
          panelClass: ['error-snackbar']
        });
      },
      complete: () => {
        console.log('done')
        this.loading = false; // Stop the spinner on success
        this.snackBar.open(this.translate.instant('messages.success'), this.translate.instant('messages.close'), {
          duration: 0,
          panelClass: ['success-snackbar']
        });
      }
    })
  }

  minStartDate() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  maxStartDate() {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    date.setHours(0, 0, 0, 0);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  maxParentBirthdate() {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    date.setHours(0, 0, 0, 0);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  maxPetBirthdate() {
    let date = new Date();
    date.setDate(date.getDate() - 56);
    date.setHours(0, 0, 0, 0);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  minPetBirthdate() {
    let date = new Date();
    date.setDate(date.getDate() - 213);
    date.setHours(0, 0, 0, 0);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
}
