import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {RouterModule, RouterOutlet, Routes} from "@angular/router";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {BreederUploadComponent} from "./components/breeder-upload/breeder-upload.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiHttpService} from "./services/api-http.service";
import {GeneralModalComponent} from "./components/general-modal/general-modal.component";
import {DragNDropComponent} from "./components/drag-n-drop/drag-n-drop.component";
import {MatDialogModule} from "@angular/material/dialog";
import { HeaderComponent } from './components/header/header.component';
import { VetClinicUploadComponent } from './components/vet-clinic-upload/vet-clinic-upload.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Routes = [
  {path: 'breeder-upload', component: BreederUploadComponent},
  {path: 'vet-clinic-upload', component: VetClinicUploadComponent},
  {path: '', redirectTo: '/breeder-upload', pathMatch: 'full'},
];

@NgModule({
  declarations: [AppComponent, GeneralModalComponent, BreederUploadComponent, HeaderComponent, VetClinicUploadComponent],
  imports: [
    CommonModule, RouterOutlet, BrowserModule, HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    RouterModule.forRoot(routes),
    BrowserAnimationsModule, DragNDropComponent,
    MatDialogModule, FormsModule, ReactiveFormsModule, NgOptimizedImage,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    ApiHttpService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
  ]
})
export class AppModule {
}
