import {Component, ViewChild} from '@angular/core';
import {DragNDropComponent} from "../drag-n-drop/drag-n-drop.component";
import * as XLSX from 'xlsx';
import {BreederGeneralInfoDto, CatBreederGeneralInfoDto, DogBreederGeneralInfoDto, PetInfoDto} from "./dto";
import {validate, validateSync} from "class-validator";
import parsePhoneNumber from 'libphonenumber-js'
import {BreederInfo} from "../../utility";
import {TranslateService} from "@ngx-translate/core";
import {ApiHttpService} from "../../services/api-http.service";
import {CloseBrokerRequestDTO, GetBrokerResponseDTO} from "../../services/dto";
import {HttpResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'calingo-admin-breeder-upload',
  templateUrl: './breeder-upload.component.html',
  styleUrls: ['./breeder-upload.component.scss']
})
export class BreederUploadComponent {
  @ViewChild('breederExcel', {static: true}) breederExcel!: DragNDropComponent;

  validationErrors: string[] = [];
  serviceErrors: string[] = [];
  uploadProgress: Array<{ name: string, status: boolean | undefined }> = [];

  breederInfo: BreederInfo | undefined;
  petInfos: CloseBrokerRequestDTO[] = [];

  rotateSyncInterval: any;

  constructor(private apiHttpService: ApiHttpService, private translate: TranslateService, private dialog: MatDialog) {
  }

  async onFileUpload() {
    this.breederExcel.spinner = true;

    let workbook = XLSX.read(this.breederExcel.files[0].file, {
      dense: true, type: "array", cellDates: true
    });
    let sheetName = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[sheetName];
    let generalInfo = worksheet['!data']?.splice(0, 3);
    let parsedGeneralInfo = generalInfo?.map((row: any) => row?.map((cell: any) => cell?.v));
    worksheet['!data']?.splice(0, 1);
    let data = XLSX.utils.sheet_to_json(worksheet, {raw: false});

    if (!parsedGeneralInfo || !data) return;

    let breeder: any = {
      brokerCode: parsedGeneralInfo[0][0],
      breed: "pedigree",
      neutered: false,
      petBirthDate: new Date(parsedGeneralInfo[2][1]),
    }

    let breederInfo: BreederGeneralInfoDto | undefined = undefined;

    if (parsedGeneralInfo[0][2] === "Katze") {
      breeder['species'] = "cat";
      breeder['catBreed'] = parsedGeneralInfo[0][3];

      breederInfo = new CatBreederGeneralInfoDto(breeder)
    } else if (parsedGeneralInfo[0][2] === "Hund") {
      breeder['species'] = "dog";
      breeder['dogBreed'] = parsedGeneralInfo[0][3];

      breederInfo = new DogBreederGeneralInfoDto(breeder)
    }

    if (!breederInfo) {
      this.breederExcel.spinner = false;
      return
    }

    const breederInfoValidationErrors = await validate(breederInfo);

    this.apiHttpService.getBrokerInfo({uid: breederInfo.brokerCode}).subscribe({
      next: (breederInfo: GetBrokerResponseDTO) => {
        this.breederInfo = {
          brokerCode: breederInfo.uid,
          brokerName: breederInfo.name,
          brokerEmail: breederInfo.email,
        }
      },
      error: (error: HttpResponse<any>) => {
        this.serviceErrors.push(this.translate.instant('excel.validationErrors.brokerCode.notFound'));
      },
      complete: () => {
        this.breederExcel.spinner = false;
      }
    });

    let petInfo: PetInfoDto[] = [];
    // @ts-ignore
    let petInfoValidationErrors: any[] = [...breederInfoValidationErrors.map(error => Object.values(error.constraints).map(error => this.translate.instant(error))).flat()];

    // the regex prevents rows with only whitespace from being parsed and causing an error down the line
    data.filter((row: any) => (row.length > 0 || Object.keys(row).length > 0) && Object.values(row).some(elem => {let regex = /\S/; return regex.test(elem as string)}) ).forEach((row: any, index: number) => {
      console.log('Parsing row', row)
      const petInfoDto = PetInfoDto.fromRow(row);
      petInfo.push(petInfoDto);
      const validationErrors = validateSync(petInfoDto);
      if (validationErrors.length > 0) {
        console.log(petInfoDto)
        // @ts-ignore
        petInfoValidationErrors.push(...validationErrors.map(error => `#${index} ${petInfoDto.petName ? petInfoDto.petName + ': ' : ''} ${Object.values(error.constraints).map(error => this.translate.instant(error))}`));
      }
      
      // has in principle been verified before, but the compiler complains
      if (breederInfo && breederInfo.petBirthDate) {
  
        petInfoDto.startDate.setHours(0,0,0,0)
        breederInfo.petBirthDate.setHours(0,0,0,0)

        let startDateNoTime = new Date(petInfoDto.startDate.getFullYear(), petInfoDto.startDate.getMonth(), petInfoDto.startDate.getDate());
        let petBirthdateNoTime = new Date(breederInfo.petBirthDate.getFullYear(), breederInfo.petBirthDate.getMonth(), breederInfo.petBirthDate.getDate());

        let differenceInMilliseconds = startDateNoTime.getTime() - petBirthdateNoTime.getTime();

        let daysDifference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24))
  
        if (daysDifference < 7*8) {
          console.warn('Birthdate:', breederInfo.petBirthDate)
          console.warn('Start date:', petInfoDto.startDate)
          console.warn('Age in diff in days:', daysDifference)
          petInfoValidationErrors.push(`#${index} ${petInfoDto.petName ? petInfoDto.petName + ': ' : ''} Pet is not old enough at insurance start date`)
        }
      }
      
    });

    this.validationErrors = [...petInfoValidationErrors]

    if (this.validationErrors.length > 0) {
      return;
    }
    // Seems to solve a timezone issue
    breederInfo.petBirthDate.setHours(8,0,0,0)

    petInfo.forEach((petInfoDto: PetInfoDto) => {
      let startDate = new Date(petInfoDto.startDate);
      startDate.setHours(8, 0, 0, 0);

      //If date in past or today, set to tomorrow
      if (startDate.getTime() <= new Date().getTime()) {
        startDate = new Date(new Date().setDate((new Date().getDate() + 1)));
        startDate.setHours(8, 0, 0, 0);
      }

      // Set to 1911-11-11 if no parent birthdate is given
      if (!petInfoDto.parentBirthdate) {
        petInfoDto.parentBirthdate = new Date(1911, 10, 11)
      }

      petInfoDto.parentBirthdate?.setHours(8, 0, 0, 0)

      this.petInfos.push({
        ...breederInfo!,
        ...petInfoDto,
        phoneNumber: parsePhoneNumber(petInfoDto.phoneNumber, 'CH')?.format('E.164'),
        startDate: startDate.toISOString().split('T')[0],
        parentBirthdate: petInfoDto.parentBirthdate?.toISOString().split('T')[0],
        petBirthDate: breederInfo!.petBirthDate.toISOString().split('T')[0],
        zip: petInfoDto.zip?.toString(),
        number: petInfoDto.number?.toString(),
      });
    });
  }

  send() {
    if (!this.breederInfo) return;
  
    this.startRotation();
  
    // recursion function
    const processPetInfo = (petInfos: CloseBrokerRequestDTO[], index: number): Promise<void> => {
      if (index >= petInfos.length) {
        // All petInfos have been processed, stop rotation and return
        this.stopRotation();
        return Promise.resolve();
      }
  
      const petInfo = petInfos[index];
  
      return new Promise<void>((resolve, reject) => {
        this.findAndSetStatus(petInfo.petName, undefined);
  
        this.apiHttpService.closeBreeder(petInfo).subscribe({
          next: (response: any) => {
            this.findAndSetStatus(petInfo.petName, true);
            resolve();
          },
          error: (error: any) => {
            this.findAndSetStatus(petInfo.petName, false);
            this.serviceErrors.push(...(error.error.errors).map((e: string) => `${petInfo.petName}: ${e}`));
            reject(error);
          },
          complete: () => {
            // Not necessary to do anything here
          }
        });
      }).then(() => processPetInfo(petInfos, index + 1));
    };
  
    processPetInfo(this.petInfos, 0)
      .catch((error) => {
        console.error('Error occurred:', error);
        this.stopRotation();
      });
  }
  
  

  startRotation() {
    const elements = document.getElementsByClassName('rotate-sync-icon');
    let rotation = 0;
    this.rotateSyncInterval = setInterval(() => {
      rotation += 2;
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('style', `transform: rotate(${rotation}deg)`);
      }
    }, 10);
  }

  stopRotation() {
    clearInterval(this.rotateSyncInterval);
  }

  reset() {
    this.breederInfo = undefined;
    this.petInfos = [];
    this.breederExcel.resetFiles();
    this.validationErrors = [];
    this.serviceErrors = [];
    this.uploadProgress = [];
  }

  findAndSetStatus(petName: string, status: boolean | undefined) {
    const petInfo = this.uploadProgress.find(petInfo => petInfo.name === petName);
    if (petInfo) {
      petInfo.status = status
    } else {
      this.uploadProgress.push({name: petName, status: status})
    }
  }
}
