import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'calingo-admin';

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('de');

    const lang = localStorage.getItem('lang') || 'de';
    this.translateService.use(lang);

    translateService.onLangChange.subscribe((event) => {
      localStorage.setItem('lang', event.lang);
    });
  }
}
