import {IsDate, IsEmail, IsIn, IsNumber, IsOptional, IsPhoneNumber, IsString} from "class-validator";

export class PetInfoDto {
  @IsString({message: 'excel.validationErrors.petName.isString'})
  petName: string;

  @IsString({message: 'excel.validationErrors.petGender.isString'})
  @IsIn(['m', 'f'], {message: 'excel.validationErrors.petGender.isIn'})
  petGender: string;

  @IsDate({message: 'excel.validationErrors.startDate.isDate'})
  startDate: Date;

  @IsString({message: 'excel.validationErrors.firstName.isString'})
  firstName: string;

  @IsString({message: 'excel.validationErrors.lastName.isString'})
  lastName: string;

  @IsEmail({}, {message: 'excel.validationErrors.email.isEmail'})
  email: string;

  @IsString({message: 'excel.validationErrors.parentGender.isString'})
  @IsIn(['m', 'f'], {message: 'excel.validationErrors.parentGender.isIn'})
  parentGender: string;

  @IsOptional()
  @IsDate({message: 'excel.validationErrors.parentBirthdate.isDate'})
  parentBirthdate?: Date;

  @IsString({message: 'excel.validationErrors.street.isString'})
  street: string;

  @IsOptional()
  // @IsNumber()
  number?: string;

  @IsOptional()
  @IsNumber()
  zip?: number;

  @IsString({message: 'excel.validationErrors.city.isString'})
  city: string;

  @IsString({message: 'excel.validationErrors.phoneNumber.isString'})
  @IsPhoneNumber('CH', {message: 'excel.validationErrors.phoneNumber.isPhoneNumber'})
  phoneNumber: string;

  coverageLevel: string = 's';
  preexistingConditions: boolean = false;
  globalCoverage: boolean = true;
  liability: boolean = false;
  excess: number = 0;
  copayment: number = 20;

  paymentFrequency: string = 'm';
  paymentMethod: string = 'invoice';
  termsAndConditions: boolean = true;

  constructor({
                petName,
                petGender,
                startDate,
                firstName,
                lastName,
                email,
                parentGender,
                parentBirthdate,
                street,
                number,
                zip,
                city,
                phoneNumber
              }: any) {
    this.petName = petName;
    this.petGender = petGender === 'w' ? 'f' : petGender;
    this.startDate = startDate;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.parentGender = parentGender === 'w' ? 'f' : parentGender;
    this.parentBirthdate = parentBirthdate;
    this.street = street;
    this.number = number;
    this.zip = zip;
    this.city = city;
    this.phoneNumber = phoneNumber;
  }

  public static columnNames = {
    petName: 'Tiername',
    petGender: 'Tiergeschlecht',
    startDate: 'Abgabedatum',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    parentGender: 'Haltergeschlecht',
    parentBirthdate: 'Haltergeburtstag',
    street: 'Strasse',
    number: 'Hausnummer',
    zip: 'Postleitzahl',
    city: 'Ort',
    phoneNumber: 'Handynummer',
  }

  public static fromRow(row: any): PetInfoDto {
    return new PetInfoDto({
      petName: row[this.columnNames.petName].trim(),
      petGender: row[this.columnNames.petGender].toLowerCase(),
      startDate: new Date(row[this.columnNames.startDate]),
      firstName: row[this.columnNames.firstName].trim(),
      lastName: row[this.columnNames.lastName].trim(),
      email: row[this.columnNames.email].trim(),
      parentGender: row[this.columnNames.parentGender].toLowerCase(),
      parentBirthdate: row[this.columnNames.parentBirthdate] ? new Date(row[this.columnNames.parentBirthdate]) : null,
      street: row[this.columnNames.street].trim(),
      number: row[this.columnNames.number],
      zip: Number.parseInt(row[this.columnNames.zip]),
      city: row[this.columnNames.city].trim(),
      phoneNumber: row[this.columnNames.phoneNumber],
    });
  }
}

