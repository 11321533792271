import {Equals, IsBoolean, IsDate, IsString, Matches, Validate,} from 'class-validator';
import {IsPet8WeeksOldConstraint} from "../../../utility";

export class BreederGeneralInfoDto {
  @IsString({message: 'excel.validationErrors.brokerCode.isString'})
  // VA1234567890
  @Matches( /VA\d{10}/, {message: 'excel.validationErrors.brokerCode.pattern'})
  brokerCode: string;

  species?: string;

  breed: string;

  @IsDate({message: 'excel.validationErrors.petBirthDate.isDate'})
  // @Validate(IsPet8WeeksOldConstraint, {message: 'excel.validationErrors.petBirthDate.isPet8WeeksOld'}) // is now dynamically validated such the the pet is 8 weeks old at start date
  petBirthDate: Date;

  @IsBoolean({message: 'excel.validationErrors.neutered.isBoolean'})
  @Equals(false, {message: 'excel.validationErrors.neutered.equals'})
  neutered: boolean;

  constructor({brokerCode, breed, petBirthDate, neutered}: any) {
    this.brokerCode = brokerCode;
    this.breed = breed;
    this.petBirthDate = petBirthDate;
    this.neutered = neutered;
  }
}

export class DogBreederGeneralInfoDto extends BreederGeneralInfoDto {
  @IsString()
  @Equals('dog')
  override species: 'dog';

  @IsString({message: 'excel.validationErrors.dogBreed.isString'})
  dogBreed: string;

  constructor({brokerCode, breed, petBirthDate, neutered, dogBreed}: any) {
    super({brokerCode, breed, petBirthDate, neutered});
    this.dogBreed = dogBreed;
    this.species = 'dog';
  }
}

export class CatBreederGeneralInfoDto extends BreederGeneralInfoDto {
  @IsString()
  @Equals('cat')
  override species: 'cat';

  housecat: boolean = true;

  @IsString({message: 'excel.validationErrors.catBreed.isString'})
  catBreed: string;

  constructor({brokerCode, breed, petBirthDate, neutered, catBreed}: any) {
    super({brokerCode, breed, petBirthDate, neutered});
    this.catBreed = catBreed;
    this.species = 'cat';
  }
}
