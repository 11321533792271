<div class="media-container">
  <div class="title-1">{{ 'vet-clinic-upload.title' | translate }}</div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!--
      <div class="form-row">
        <div class="form-field">
          <label>{{ 'vet-clinic-upload.fields.VAnumber' | translate }}</label>
          <input type="text" name="VAnumber" formControlName="VAnumber"/>
        </div>
      </div>
      -->

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.petName' | translate }}</label>
        <input type="text" name="petName" formControlName="petName"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.species' | translate }}</label>
        <div class="radio-wrapper">
          <label for="cat" [class.selected]="form.getRawValue().species==='cat'">
            <input id="cat" type="radio" value="cat" formControlName="species">
            <div class="radio-div">
              <div class="image-bg">
                <img ngSrc="assets/calingo_icon_cat.svg" alt="Cat" width="85" height="85">
              </div>
              <span>{{ 'vet-clinic-upload.fields.cat' | translate }}</span>
            </div>
          </label>
          <label for="dog" [class.selected]="form.getRawValue().species==='dog'">
            <input id="dog" type="radio" value="dog" formControlName="species">
            <div class="radio-div">
              <div class="image-bg">
                <img ngSrc="assets/calingo_icon_dog.svg" alt="Dog" width="85" height="85">
              </div>
              <span>{{ 'vet-clinic-upload.fields.dog' | translate }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.pedigree-label' | translate }}</label>
        <div class="radio-text-div">
          <label for="pedigree" [class.selected]="form.getRawValue().pedigree==='pedigree'">
            <input id="pedigree" type="radio" value="pedigree" formControlName="pedigree">
            <span>{{ 'vet-clinic-upload.fields.pedigree' | translate }}</span>
          </label>

          <label for="cross" [class.selected]="form.getRawValue().pedigree==='cross'">
            <input id="cross" type="radio" value="cross" formControlName="pedigree">
            <span>{{ 'vet-clinic-upload.fields.cross' | translate }}</span>
          </label>

          <label for="mixed" [class.selected]="form.getRawValue().pedigree==='mixed'">
            <input id="mixed" type="radio" value="mixed" formControlName="pedigree">
            <span>{{ 'vet-clinic-upload.fields.mixed' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="showBreedSelector()">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.breed' | translate }}</label>
        <mat-form-field>
          <mat-select
            name="breed"
            formControlName="breed"
            (openedChange)="onOpen()"
          >
            <mat-option>
              <ngx-mat-select-search [placeholderLabel]="'Search Item'" [noEntriesFoundLabel]="'No matching records found'"  [formControl]="breedFilterCtrl"
              ngDefaultControl></ngx-mat-select-search>
            </mat-option>

            <mat-option class="m-t" *ngFor="let item of filteredBreeds | async" [value]="item.id">
              {{item.name}}
            </mat-option>

          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row" *ngIf="showTallDogQuestion()">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.tall-dog' | translate }}</label>
        <div class="radio-text-div">
          <label for="tall-dog-yes" [class.selected]="form.getRawValue().tallDog">
            <input id="tall-dog-yes" type="radio" [value]="true" formControlName="tallDog">
            <span>{{ 'vet-clinic-upload.fields.yes' | translate }}</span>
          </label>

          <label for="tall-dog-no" [class.selected]="!form.getRawValue().tallDog">
            <input id="tall-dog-no" type="radio" [value]="false" formControlName="tallDog">
            <span>{{ 'vet-clinic-upload.fields.no' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="showHouseCatQuestion()">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.house-cat' | translate }}</label>
        <div class="radio-text-div">
          <label for="house-cat-yes" [class.selected]="form.getRawValue().housecat">
            <input id="house-cat-yes" type="radio" [value]="true" formControlName="housecat">
            <span>{{ 'vet-clinic-upload.fields.yes' | translate }}</span>
          </label>

          <label for="house-cat-no" [class.selected]="!form.getRawValue().housecat">
            <input id="house-cat-no" type="radio" [value]="false" formControlName="housecat">
            <span>{{ 'vet-clinic-upload.fields.no' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.petBirthDate' | translate }}</label>
        <input type="date" name="petBirthDate" formControlName="petBirthDate" [min]="minPetBirthdate()" [max]="maxPetBirthdate()"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.neutered' | translate }}</label>
        <div class="radio-text-div">
          <label for="neutered-yes" [class.selected]="form.getRawValue().neutered">
            <input id="neutered-yes" type="radio" [value]="true" formControlName="neutered">
            <span>{{ 'vet-clinic-upload.fields.yes' | translate }}</span>
          </label>

          <label for="neutered-no" [class.selected]="!form.getRawValue().neutered">
            <input id="neutered-no" type="radio" [value]="false" formControlName="neutered">
            <span>{{ 'vet-clinic-upload.fields.no' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.petGender' | translate }}</label>
        <div class="radio-text-div">
          <label for="male" [class.selected]="form.getRawValue().petGender === 'm'">
            <input id="male" type="radio" value="m" formControlName="petGender">
            <span>{{ 'vet-clinic-upload.fields.male' | translate }}</span>
          </label>

          <label for="female" [class.selected]="form.getRawValue().petGender === 'f'">
            <input id="female" type="radio" value="f" formControlName="petGender">
            <span>{{ 'vet-clinic-upload.fields.female' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.startDate' | translate }}</label>
        <input type="date" name="startDate" formControlName="startDate" [min]="minStartDate()" [max]="maxStartDate()"/>
      </div>
    </div>

    <div class="title-1">{{ 'vet-clinic-upload.owner-info' | translate }}</div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.firstName' | translate }}</label>
        <input type="text" name="firstName" formControlName="firstName"/>
      </div>

      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.lastName' | translate }}</label>
        <input type="text" name="lastName" formControlName="lastName"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.email' | translate }}</label>
        <input type="email" name="email" formControlName="email"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.phoneNumber' | translate }}</label>
        <input type="text" name="phoneNumber" formControlName="phoneNumber"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.parentGender' | translate }}</label>
        <div class="radio-text-div">
          <label for="male-parent" [class.selected]="form.getRawValue().parentGender === 'm'">
            <input id="male-parent" type="radio" value="m" formControlName="parentGender">
            <span>{{ 'vet-clinic-upload.fields.male' | translate }}</span>
          </label>

          <label for="female-parent" [class.selected]="form.getRawValue().parentGender === 'f'">
            <input id="female-parent" type="radio" value="f" formControlName="parentGender">
            <span>{{ 'vet-clinic-upload.fields.female' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.parentBirthdate' | translate }}</label>
        <input type="date" name="parentBirthdate" formControlName="parentBirthdate" [max]="maxParentBirthdate()"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.zip' | translate }}</label>
        <input type="text" name="zip" formControlName="zip"/>
      </div>

      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.city' | translate }}</label>
        <input type="text" name="city" formControlName="city"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.street' | translate }}</label>
        <input type="text" name="street" formControlName="street"/>
      </div>

      <div class="form-field">
        <label>{{ 'vet-clinic-upload.fields.number' | translate }}</label>
        <input type="text" name="number" formControlName="number"/>
      </div>
    </div>

    <div *ngIf="loading" class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>

    <div class="form-row">
      <button type="submit" [disabled]="loading">{{ 'vet-clinic-upload.buttons.submit' | translate }}</button>
    </div>
  </form>



</div>
