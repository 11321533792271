<div class="media-container">
  <div class="title-1">{{'breeder-upload.title'|translate}}</div>

  <div class="breeder-info" *ngIf="breederInfo">
    <span class="breeder-info-name">{{breederInfo.brokerName}},
    </span>
    <span class="breeder-info-email">{{breederInfo.brokerEmail}},
    </span>
    <span class="breeder-info-code">{{breederInfo.brokerCode}}
    </span>
  </div>

  <div class="upload-progress" *ngIf="uploadProgress.length>0">
    <div class="upload-progress-pet" *ngFor="let petProgress of uploadProgress">
      <div class="upload-progress-pet-name">{{petProgress.name}}</div>

      <div class="upload-progress-pet-status">
        <span class="material-symbols-outlined" *ngIf="petProgress.status">done</span>
        <span class="material-symbols-outlined rotate-sync-icon" *ngIf="petProgress.status===undefined">sync</span>
        <span class="material-symbols-outlined" *ngIf="petProgress.status===false">error</span>
      </div>
    </div>
  </div>

  <div class="errors" *ngIf="validationErrors.length">
    <p class="error" *ngFor="let error of validationErrors">{{error}}</p>
  </div>

  <div class="errors" *ngIf="serviceErrors.length">
    <p class="error" *ngFor="let error of serviceErrors">{{error}}</p>
  </div>

  <calingo-drag-n-drop [singleFileAllowed]="true" (onFileChange)="onFileUpload()" [allowDelete]="false"
                       #breederExcel></calingo-drag-n-drop>

  <div class="actions">
    <button class="button" [class.button-inactive]="breederExcel.files.length==0"
            [disabled]="breederExcel.files.length==0 " (click)="reset()">
      {{'breeder-upload.actions.clear' | translate}}
    </button>
    <button class="button"
            [class.button-inactive]="breederExcel.files.length==0 || validationErrors.length>0 || serviceErrors.length>0"
            [disabled]="breederExcel.files.length==0 || validationErrors.length>0 || serviceErrors.length>0"
            (click)="send()">
      {{'breeder-upload.actions.upload' | translate}}
    </button>
  </div>
</div>
