<div class="flex-placeholder">
</div>

<!--

<div class="nav-button-container">
  <a class="nav-button" *ngFor="let button of buttons" [href]="button.link" [class.active]="button.active">
    {{ button.title }}
  </a>
</div>
-->

<div *ngIf="false" class="lang-select-container">
  <div class="lang" [class.active]="currentLang==='en'" (click)="changeLang('en')">
    EN
  </div>

  <div class="lang" [class.active]="currentLang==='de'" (click)="changeLang('de')">
    DE
  </div>

  <div class="lang" [class.active]="currentLang==='it'" (click)="changeLang('it')">
    IT
  </div>

  <div class="lang" [class.active]="currentLang==='fr'" (click)="changeLang('fr')">
    FR
  </div>
</div>
