import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileInfo} from "../../utility";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import * as XLSX from "xlsx";

@Component({
  selector: 'calingo-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss'],
  imports: [
    TranslateModule, CommonModule
  ],
  standalone: true
})
export class DragNDropComponent implements OnInit {
  @Input() singleFileAllowed: boolean = false;
  @Input() validate: boolean = false;
  @Input() allowDelete: boolean = true;

  @Output() onFileChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileUpload', {static: true}) fileUpload!: ElementRef;

  fileList: FileInfo[] = [];
  isFileDragOver = false;
  isValid = true;
  showSpinner = false;
  rotateSyncInterval: any;

  constructor() {
  }

  get files() {
    return this.fileList;
  }

  set files(files: FileInfo[]) {
    this.fileList = files;
  }

  get filesSize() {
    return this.fileList.reduce((acc, curr) => acc + (curr.size || 0), 0);
  }

  set spinner(show: boolean) {
    if (show) {
      this.startRotation();
    } else {
      this.stopRotation();
    }
    this.showSpinner = show;
  }

  get spinner() {
    return this.showSpinner;
  }

  ngOnInit(): void {
  }

  resetFiles() {
    this.fileList = [];
    this.fileUpload.nativeElement.value = '';
  }

  deleteFile(file: FileInfo) {
    this.fileList = this.fileList.filter(f => f !== file);
    this.onFileChange.emit();
  }

  dragEnterHandler(event: any) {
    this.isFileDragOver = true;
    event.preventDefault();
  }

  dragLeaveHandler(event: any) {
    this.isFileDragOver = false;
    event.preventDefault();
  }

  dragOverHandler(event: any) {
    event.preventDefault();
  }

  async dropHandler($event: DragEvent) {
    $event.preventDefault();

    if ($event.dataTransfer?.items) {

      if (this.singleFileAllowed && $event.dataTransfer.items.length > 1) {
        alert('Only one file is allowed');
        return;
      }

      for (let i = 0; i < $event.dataTransfer.items.length; i++) {
        let item = $event.dataTransfer.items[i];

        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            this.fileList.push(await this.toBase64(file));
            this.onFileChange.emit();
          }
        }
      }
    } else {
      if ($event.dataTransfer?.files) {
        if (this.singleFileAllowed && $event.dataTransfer.files.length > 1) {
          alert('Only one file is allowed');
          return;
        }

        for (let i = 0; i < $event.dataTransfer?.files?.length; i++) {
          this.fileList.push(await this.toBase64($event.dataTransfer?.files[i]));
          this.onFileChange.emit();
        }
      }
    }
  }

  async fileChangeHandler(event: any) {
    if (event.target.files) {
      if (this.singleFileAllowed && event.target.files.length > 1) {
        alert('Only one file is allowed');
        return;
      }

      for (let i = 0; i < event.target.files.length; i++) {
        this.fileList.push(await this.toBase64(event.target.files[i]));
        this.onFileChange.emit();
      }
    }
  }

  toBase64 = (file: File): Promise<FileInfo> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve({
      name: file.name,
      data64: reader.result as string,
      size: file.size,
      file: e.target?.result
    })
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });

  startRotation() {
    const elements = document.getElementsByClassName('rotate-sync-icon');
    let rotation = 0;
    this.rotateSyncInterval = setInterval(() => {
      rotation += 2;
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('style', `transform: rotate(${rotation}deg)`);
      }
    }, 10);
  }

  stopRotation() {
    clearInterval(this.rotateSyncInterval);
  }
}
